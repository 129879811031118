
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('./assets/fonts/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
}

:root{
  --color-primary:#D70A84;
  --color-secondary:#51127F;
  --color-rootcode:#FFCA03;

  --color-primary-variant:#FFCBF2;
  --color-secondary-variant:#D9AFFF;

  --color-text-primary:#000;
  --color-text-secondary:#fff;
  --color-text-gray:#656565;

  --color-bg-primary:#fff;
  --color-bg-secondary:#51127F;

  --color-bg-primary-variant:#E0DEE4;
  --color-bg-secondary-variant:#F8F2FD; 
  /* F5F5F5 */

}

body{
  overflow-x: hidden;
  color:var(--color-text-primary);
  background-color: var(--color-bg-primary);
  font-family: 'SpaceGrotesk', sans-serif;
}

section{
  text-align: center;
  overflow: hidden;
  line-height: 1.8rem;
  margin:0 5rem 5rem 5rem;
}

.primary-btn {
  background-color: var(--color-secondary);
  border-radius: 40px;
  padding: 0.4rem 1.5rem;
  border: 0;
  color: var(--color-text-secondary);
  width: fit-content;
  cursor: pointer;
}

.primary-btn:hover {
  background-color: var(--color-primary);
}

.secondary-btn {
  background-color: var(--color-primary);
  border-radius: 40px;
  padding: 0.4rem 1.5rem;
  color: var(--color-text-secondary);
  width: fit-content;
  cursor: pointer;

}

.secondary-btn:hover {
  background-color: var(--color-secondary);
}


@media only screen and (max-width: 1024px) {
  section{
      text-align: center;
      overflow: hidden;
      line-height: 1.2rem;
      margin:0 1.8rem 1rem 1.8rem;
  }
}

