.common-btn{
    background: rgba(215, 10, 132, 1) ;
    border-radius: 50px ;
    min-width: 17%;
    align-self: center ;
    color: white;
    margin-top: 10px;
    padding:7px 20px;
    
    border: 0px;
    min-width: 100px;
}

.common-btn:hover {
    background-color: #5F2294 !important;
    animation: pulse 0.8s ease-out;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(1.05);
    }
  
    100% {
      transform: scale(1);
    }
  }
  