.styled-input-single {
  position: relative;
  padding: 5px 0 5px 40px;
  text-align: left;
}

.styled-input-single label {
  cursor: pointer;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 500;
  color: #6f8ba4;
}

.styled-input-single label:before,
.styled-input-single label:after {
  content: '';
  position: absolute;
  top: 50%;
  border-radius: 50%;
}

.styled-input-single label:before {
  left: 0;
  width: 25px;
  height: 25px;
  margin: -12px 0 0;
  background: #f7f7f7;
  box-shadow: 0 0 2px #5f2294;
}

.styled-input-single label:after {
  left: 5px;
  width: 15px;
  height: 15px;
  margin: -7px 0 0;
  opacity: 0;
  background: linear-gradient(to right, #d70a84, #5f2294);
  transform: translate3d(0, 0, 0) scale(0.5);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.styled-input-single input[type='radio'],
.styled-input-single input[type='checkbox'] {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}

.styled-input-single input[type='radio']:checked + label:before,
.styled-input-single input[type='checkbox']:checked + label:before {
}

.styled-input-single input[type='radio']:checked + label:after,
.styled-input-single input[type='checkbox']:checked + label:after {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.styled-input--square label:before,
.styled-input--square label:after {
  border-radius: 0;
}

.styled-input--rounded label:before {
  border-radius: 5px;
}

.styled-input--rounded label:after {
  border-radius: 4px;
}

.styled-input--diamond .styled-input-single {
  padding-left: 45px;
}

.styled-input--diamond label:before,
.styled-input--diamond label:after {
  border-radius: 0;
}

.styled-input--diamond label:before {
  transform: rotate(45deg);
}

.styled-input--diamond input[type='radio'],
.styled-input--diamond input[type='checkbox'] {
}

.styled-input--diamond input[type='radio']:checked + label:after,
.styled-input--diamond input[type='checkbox']:checked + label:after {
  transform: rotate(45deg);
  opacity: 1;
}

.pen-intro {
  text-align: center;
}

.two-column:after {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 860px) {
  .two-column {
    display: flex;
  }

  .two-column .single-col {
    width: 47.5%;
    float: left;
    margin: 0 0 0 5%;
  }

  .two-column .single-col:nth-child(2n + 1) {
    clear: both;
    margin: 0;
  }
}

.styled-input-single {
  display: flex;
  align-items: center;
  /* Ensures the checkbox and label are aligned on the same line */
}
