.card-header{
    font-weight: 700;
}
input{
    border: none !important;
    border-radius: 5px !important;
}
.btn{
    border: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    
  
}

.form-control-resetform:focus {
    box-shadow: none !important; 
    border-color: #ced4da !important; 
    outline: 0 !important; 
}