.table{
    height: 500; 

}

.MuiButton-text{
    color: #1976d2 !important;
}

.MuiDataGrid-columnHeader:focus-within{
    outline: none !important;
}

.MuiDataGrid-cell:focus{
    outline: none !important;
}

.MuiDataGrid-columnHeaderTitle{
    font-weight: 700 !important;
}