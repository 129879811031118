
@media only screen and (min-width:1441px) {
    .login-container {
        display: flex;
        flex-direction: row;
        max-width: 1440px;
        padding: 0px 192px;
        margin: 0px auto;
        min-height: calc(100vh - 86px);
    }
}

@media only screen and (max-width:1440px) {
    .login-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width:100%;
        min-height: calc(100vh - 86px);
    }
}

@media only screen and (min-width:1441px) {
    .pink-back{
        position: absolute;
        background-color: #F8F2FD;
        right:calc(100vw/2);
        width:calc(100vw/2);
        height: calc(100vh - 86px);;
        z-index: 10;
    }
}
