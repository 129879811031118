.footer-container {
  background-color: var(--color-bg-secondary);
  color: var(--color-text-secondary);
  padding: "20px 0";
  display: flex;
  padding: 96px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  box-shadow: 0px 2px 8px 0px rgba(25, 1, 52, 0.08);
}

.footer-container .contend {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.footer-container .contend .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.footer-container .contend .col .img-background {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer-container .contend .col .icons {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
