.student-card{
  background-color: var(--color-bg-secondary-variant);
  padding: 0.9rem;
  width: 90%;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  cursor: default;
  border: 1px solid transparent;
  box-shadow: 2px 2px 4px 2px rgba(81, 18, 127, 0.1);
}

.student-card:hover{
  box-shadow: 2px 2px 4px 2px rgba(81, 18, 127, 0.2);
}

.student-card .card-left,
.student-modal-card .card-left{
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.student-card .card-right,
.student-modal-card .card-right{
  width: 80%;
  

}

.student-card .card-left .img-container{
  
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  

}

.student-card .card-left .img-container img{
  width: 10rem;
  border-radius: 50%;
}


.student-card .card-right .header-bar,
.student-modal-card .card-right .header-bar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.student-card .card-right .header-bar .name,
.student-modal-card .card-right .header-bar .name{
  font-size: x-large;
  font-weight: 800;
}

.student-card .card-right .position-section,
.student-modal-card .card-right .position-section{
  margin-top: 0.4rem;
  
}

.student-card .card-right .position-section .positions,
.student-modal-card .card-right .position-section .positions{

  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 0.4rem;
  width: fit-content;
  margin-top: 0.8rem;
}

.student-card .card-right .position-section .positions .position-item,
.student-modal-card .card-right .position-section .positions .position-item{
  background-color: var(--color-secondary-variant);
  width: fit-content;
  padding: 0.1rem 0.4rem;
  border-radius: 5px;
  white-space: nowrap;
}

.student-card .card-right .position-section .positions .position-item:hover,
.student-modal-card .card-right .position-section .positions .position-item:hover{
  background-color: var(--color-primary-variant);
}

.student-card .card-right .std-description,
.student-modal-card .card-right .std-description{
  margin-top: 1rem;
  width: 90%;
}


.student-card .card-right .technology-section .technologies,
.student-modal-card .card-right .technology-section .technologies{

  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto ;
  gap: 0.4rem;
  width: fit-content;
  margin-top: 0.8rem;
}
.student-card .card-right .technologies .tech-item,
.student-modal-card .card-right .technologies .tech-item{
  background-color: var(--color-primary-variant);
  width: fit-content;
  padding: 0.1rem 0.4rem;
  border-radius: 5px;
  white-space: nowrap;
}

.student-card .card-right .technologies .tech-item:hover,
.student-modal-card .card-right .technologies .tech-item:hover{
  background-color: var(--color-secondary-variant);
}

/* 
Student Card Modal Styling
 */

 .student-modal-card{

  background-color: var(--color-bg-secondary-variant);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  border-radius: 20px;
  cursor: default;

}

.student-modal-card .img-container img{
  width: 16rem;
  border-radius: 10px;
}

.student-modal-card .contact-section{
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  margin-top: 1.2rem;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1.2rem;
}

.student-modal-card .contact-section .contact-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
  font-size: small;
  white-space: nowrap;
}

.student-modal-card .contact-section .contact-row .icon{
  margin-top: 0.2rem;
  margin-right: 0.6rem;
}

.student-modal-card .contact-section .contact-row .title{
  font-weight: 600;
}

.linkedin-btn{
  border: none;
}
@media only screen and (max-width: 1200px) {

  .student-card .card-left .img-container img{
    width: 9rem;
  }
  .student-card .card-right .technologies,
  .student-modal-card .card-right .technologies{
    /* grid-template-columns: auto auto auto auto auto auto auto; */
    font-size: small;
  }

  .student-card .card-right .position-section .positions,
  .student-modal-card .card-right .position-section .positions{

    grid-template-columns: auto auto auto;

  }

  .student-modal-card .img-container img{
    width: 12rem;
  }
}

@media only screen and (max-width: 991px) {

  .student-card{
    flex-direction: column;


  }

  .student-card .card-left .img-container img{
    width: 10rem;
  }

  .student-card .card-left .img-container{
    width: max-content;
  }

  .student-card .card-right .header-bar,
  .student-modal-card .card-right .header-bar{
    flex-direction: column;
    margin-top: -1rem;
  }

  .student-card .card-right .position-section
  .student-modal-card .card-right .position-section{
    text-align: center;
    margin-top: 2rem;
  }
  .student-card .card-right .position-section .positions,
  .student-modal-card .card-right .position-section .positions{
  
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */

    display: grid;
    grid-template-columns: auto auto;
    font-size: x-small;
    width: 100%;
  }

  .student-card .card-right .std-description,
  .student-modal-card .card-right .std-description{
    text-align: center;
    width: 100%;
    font-size: smaller;
  }

  .student-card .card-right .technology-section,
  .student-modal-card .card-right .technology-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
  }

  .student-card .card-right .technology-section .technologies,
  .student-modal-card .card-right .technology-section .technologies
  {
    grid-template-columns: auto auto  auto auto;
    gap: 0.3rem;
    text-align: justify;
    font-size: x-small;


  }

  .student-modal{
    margin: 0 2rem;
  }
  .student-modal-card{
    flex-direction: column;
  }

  .student-modal-card .img-container img{
    width: 8rem;
  }


  .linkedin-btn{
    text-align: center;
  }
}


.add-padding {
  /* padding-left: 100px; */
  padding-right: 100px;
}

/* paginator CSS */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  list-style: none;
  display: inline-block;
  margin-right: 5px;
  color: #51127F;
}

.pagination li:hover {
  list-style: none;
  display: inline-block;
  margin-right: 5px;
  color: #fff;;
}

.pagination li a {
  color: #D70A84;
  padding: 8px 12px;
  text-decoration: none;
  border: 1px solid #D70A84;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.pagination li a:hover {
  background-color: #D70A84;
  color: #fff;
}

.pagination .active a {
  background-color: #D70A84;
  color: #fff;
}

.pagination .disabled a {
  pointer-events: none;
  color: #ccc;
  border-color: #ccc;
}

.pagination .disabled{
  pointer-events: none;
  color: #ccc;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-decoration: none;
}

/* Reset the default left padding for hits container. */
.hits-container > ol{
  padding-left: 0;
}