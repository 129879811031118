.navbar {
  background: var(--Ui-Text-White, #fff);
  box-shadow: 0px 5px 8px 0px rgba(25, 1, 52, 0.08);
}

.navbar .navlink a {
  color: var(--color-text-primary);
  font-weight: 500;
}

.navbar .active-nav, .navbar .navlink a:hover{
  color: var(--color-primary);
}

.navbar .active-nav, .navbar .navlink a.active {
  color: var(--color-primary);
}

.find {
  background-color: blueviolet;
}

.primary-btn-nav {
  background-color: #D70A84 !important;
  border-radius: 40px !important;
  padding: 0.4rem 1.5rem !important;
  border: 0 !important;
  color: #fff !important;
  width: fit-content !important;
  cursor: pointer !important;
}

.primary-btn-nav:hover {
  background-color: #51127F !important;
}
