.style {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: 2px;
    border-radius: 10px;
    padding: 20px;
    outline: 0;
}

.btn-success{
  color: #fff;
  background-color: #2ab27b;
  border-color: #259d6d;
}

.btn-default{
  color: #636b6f;
  background-color: #fff;
  border-color: #ccc;
}

h6{
  text-align: left;
}