.form-control-search{
    background-color: #F8F2FD !important;
}

.form-control-search:focus {
    box-shadow: none!important;
    border: none!important;
    outline: none!important;

}

.button-icon:focus {
    box-shadow: none !important;
}

.form-control-search::placeholder {
    font-size: 1rem;
    color: #aaa;
    font-style: italic;
}

.input-group {
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 2px rgba(81, 18, 127, 0.1);
}

.input-group:hover {
    box-shadow: 2px 2px 4px 2px rgba(82, 18, 127, 0.295);
}

.form-color {
    background-color: #F8F2FD;
}

.align-search-bar {
    padding-top: 30px;
}

.btn-color {
    color: black;
}

@media (max-width: 800px) {
    .col-md-6 {
        flex: 0 0 100%;
        max-width: 80%;
    }
}

.align-left {
    margin-top: 5px !important;
    margin-left: 10px;
}

.basic-text-search {
    font-size: 18px;
    color: #6F8BA4;
}

.icon-color {
    color: #5F2294;
}

@media (max-width: 300px) {
    .align-left {
      display: none;
    }
  }