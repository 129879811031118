/*===========================
    01.COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&family=Heebo:wght@300;400;500;600;700&display=swap");
html {
  scroll-behavior: smooth;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


img{
    width: 100%;
    
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: " Space Grotesk";
  font-weight: 700; 
  margin: 0px;
  color: #51127F;
  
}


h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  
}

h1 {
  font-size: px;
  text-align: left;
  margin-bottom: 0px;
  padding-bottom: 0px;

}
.text-card{
  background-color: #51127F;
  color: #FFFFFF;
  height:auto;
  align-items: center;
  padding: 10px;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: center;
  border-radius: 16px;
  margin-top: 2%;
  background-size:contain;
  margin-right: 6%;
  
}
.text-card-text{
  font:Figtree;
  font-size: 16px;
  font-weight: 400;
}

h5{
  font: Readex Pro;
  font-size: 24px;
  font-weight: 500;
  color:#000000;
  text-align: left;
}
.name-card{ 
  position:relative;  
  left: 0;
}
.flex-container{
  display: flex;
  flex-direction: row;
  bottom: 0;
}

@media (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  h1 {
    font-size: 40px;
  }
}

.section-title span {
  font-size: 25px;
  font-weight: 600;
  color: #2f079d;
  margin-bottom: 20px;
}

h2 {
    font: "Space Grotesk";
  font-size: 56px;
  color: #D70A84;
  text-align: left;
  margin-bottom: 16px;

}

h3 {
  font: Space-Grotesk;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 2%;

}

h4 {
  
  color: #D70A84;  
  font: Figtree;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2%;
}



h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  margin: 0px;
  text-align: left
}

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.error {
  color: orangered;
}

.success {
  color: #fff;
}
.mora-logo{
 max-width:150px;
 height: 150px;
 margin-right: 6%;
 
}
.intecs-logo{
  max-width:150px;
  height: 150px;
}

.card-img-top {
  width: 80%; /* Adjust this to control the background width */
  align-self: center;
  margin-top: 7%;
  background-color: #FFFFFF;
  padding-left: 18%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-right: 18%;
  border-radius: 5%;
  display: flex;
  justify-content: center;
}





@keyframes animation1 {
  0% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(30px);
  }
}

/*===== All Preloader Style =====*/
.preloader {
  /* Body Overlay */
  position: fixed;
  top: 0;
  left: 0;
  display: table;
  height: 100%;
  width: 100%;
  /* Change Background Color */
  background: #fff;
  z-index: 99999;
}

.preloader .loader {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.preloader .loader .ytp-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 64px;
  margin-left: -32px;
  z-index: 18;
  pointer-events: none;
}

.preloader .loader .ytp-spinner .ytp-spinner-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  animation: ytp-spinner-linspin 1568.2353ms linear infinite;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  right: 50%;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  left: 50%;
}

.preloader .loader .ytp-spinner-circle {
  box-sizing: border-box;
  position: absolute;
  width: 200%;
  height: 100%;
  border-style: solid;
  /* Spinner Color */
  border-color: #E8505B #E8505B #ddd;
  border-radius: 50%;
  border-width: 6px;
}

.preloader .loader .ytp-spinner-left .ytp-spinner-circle {
  left: 0;
  right: -100%;
  border-right-color: #ddd;
  animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .ytp-spinner-right .ytp-spinner-circle {
  left: -100%;
  right: 0;
  border-left-color: #ddd;
  animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

/* Preloader Animations */

@keyframes ytp-spinner-linspin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ytp-spinner-easespin {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

@keyframes ytp-spinner-left-spin {
  0% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}

@keyframes ytp-right-spin {
  0% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}

/*===========================
    02.HEADER css 
===========================*/
/*===== NAVBAR =====*/
.navbar-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: all 0.3s ease-out 0s;
}

.sticky {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-out 0s;
}

.sticky .navbar {
  padding: 10px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky .navbar {
    padding: 10px 0;
  }
}

@media (max-width: 767px) {
  .sticky .navbar {
    padding: 10px 0;
  }
}

.sticky .navbar .navbar-nav .nav-item a {
  color: #222;
}

.sticky .navbar .navbar-nav .nav-item a::before {
  opacity: .5;
}

.sticky .navbar .navbar-toggler .toggler-icon {
  background: #263238;
}



.hero-section {
  position: relative;
  z-index: 1;
}

.hero-section .hero-shape {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: -100px;
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-section {
    /* padding-top: 150px; */
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-section {
    /* padding-top: 160px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-section {
    /* padding-top: 160px; */
  }
}

@media (max-width: 767px) {
  .hero-section {
    /* padding-top: 160px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-section .hero-content {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .hero-section .hero-content {
    margin-bottom: 50px;
  }
}

.hero-section .hero-content h1 {
  font-size: 56px;
  margin-bottom: -5px;

}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-section .hero-content h1 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-section .hero-content h1 {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .hero-section .hero-content h1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hero-section .hero-content h1 {
    font-size: 56px;

    ;
  }
}

.hero-section .hero-content h1 span {
  font-weight: 400;
  display: inline;
}

.hero-section .hero-content p {
  margin-bottom: 35px;
  padding-right: 55px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-section .hero-content p {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .hero-section .hero-content p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-section .hero-img img {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-section .hero-img img {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-section .hero-img img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .hero-section .hero-img img {
    width: 100%;
  }
}


.partners-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.partner {
  margin: 10px;
  text-align: center;
}

.partners-silver-image {
  max-width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .delivery-img {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .delivery-img {
    margin-bottom: 50px;
  }
}

.delivery-img img {
  width: 100%;
}

.testimonial-section .testimonial-wrapper {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-section .testimonial-wrapper {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .testimonial-section .testimonial-wrapper {
    margin-top: 0;
  }
}

.testimonial-section .testimonial-wrapper .-mt-30 {
  margin-top: -30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-section .testimonial-wrapper .-mt-30 {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .testimonial-section .testimonial-wrapper .-mt-30 {
    margin-top: 0px;
  }
}

.testimonial-section .testimonial-wrapper .-mt-60 {
  margin-top: -60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-section .testimonial-wrapper .-mt-60 {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .testimonial-section .testimonial-wrapper .-mt-60 {
    margin-top: 0px;
  }
}

.testimonial-section .single-testimonial {
  padding: 40px 30px;
  border: 1px solid #E2E2E2;
  border-radius: 10px;
  margin-bottom: 30px;
  transition: all 0.3s ease-out 0s;
}

.testimonial-section .single-testimonial:hover {
  box-shadow: 0px 0px 40px rgba(149, 149, 149, 0.25);
  border-color: transparent;
}

.testimonial-section .single-testimonial .rating {
  margin-bottom: 30px;
  color: #E96A20;
}

.testimonial-section .single-testimonial .rating i {
  margin-right: 10px;
  font-size: 14px;
}

.testimonial-section .single-testimonial .content {
  margin-bottom: 30px;
}

.testimonial-section .single-testimonial .info {
  display: flex;
  align-items: center;
}

.testimonial-section .single-testimonial .info .image {
  max-width: 48px;
  width: 100%;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.testimonial-section .single-testimonial .info .image img {
  width: 100%;
}

.testimonial-section .single-testimonial .info .text h5 {
  font-weight: 500;
}

.testimonial-section .single-testimonial .info .text p {
  font-size: 16px;
}

.partner-section .single-partner {
  opacity: .43;
  transition: all 0.3s ease-out 0s;
}

.partner-section .single-partner:hover {
  opacity: 1;
}
.partners-card{
  padding: 5%;
  margin-top: 80px; 
  background: radial-gradient(circle closest-side at center, rgba(81,18,127, 0.2), rgba(255, 255, 255, 0.0));
}

.partners-section-platinum-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #271452;
}
.partners-platinum-image {
  width: 221px;
  height: 110px;
  border-radius: 25px;
	object-fit: cover;
}
.partners-gold-image {
  border-radius: 15px;
  box-shadow: 0 0 6px rgba(0,0,0,0.2);
}
.partners-silver-image {
  width: 150px;
  border-radius: 15px;
	margin-top: 28px;
  box-shadow: 0 0 6px rgba(0,0,0,0.2);
  margin-left: 5px;
  margin-right: 5px;
}
.partners-silver-image2 {
  width: 300px;
  border-radius: 15px;
	margin-top: 28px;
  box-shadow: 0 0 6px rgba(0,0,0,0.2);
  margin-left: 5px;
  margin-right: 5px;
}
.gradient {
  background: linear-gradient(92.39deg, #24225c 0.79%, #6529ad 117.11%);
}

.card {
  border-radius: 1rem;
}
.partners-gold-image {
  width: 221px;
  height: 110px;
  border-radius: 15px;
  box-shadow: 0 0 6px rgba(0,0,0,0.2);
}
