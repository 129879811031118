.form-control-field:focus {
  box-shadow: 0 0 0 0.1rem rgb(235, 165, 207)!important;
}

@media only screen and (max-width:768px) {
  .input-field {
    display: flex !important;
    border-radius: 8px !important;
    background-color: rgba(250, 244, 255, 1) !important;
    width: 100% !important;
    height: 56px !important;
    border: 0px !important;
    padding-left: 5px !important;
  }
  .input-field :focus { 
  outline: none !important;
  border-color: #719ECE !important;
  box-shadow: 0 0 10px #719ECE !important;
}
}

@media only screen and (min-width:769px) {
  .input-field {
    display: flex !important;
    border-radius: 8px !important;
    background-color: rgba(250, 244, 255, 1)!important;
    width: 90% !important;
    height: 56px !important;
    border: 0px !important;
    padding-left: 3% !important;
  }
  .input-field :focus { 
    outline: none !important;
    border-color: #719ECE !important;
    box-shadow: 0 0 10px #719ECE !important;
  }
}

