@media only screen and (max-width: 375px) {
  .widgetLarge {
    width: 100%;
  }
}


@media screen and (min-width: 1441px) {
  .companiesPage {
    max-width: 1440px;
    margin: 0 auto;
    padding: 72px 96px;
  }
}

@media screen and (max-width: 1440px) {
  .companiesPage {
    width: 100%;
    margin: 0 0;
    padding: 6% 8%;
  }
}



.body {
  width: Fixed (1, 440px);
  height: Hug (337px);
  gap: 8px;
  padding-bottom: 3%;
}

.Topic {
  width: 96px;
  height: 22px;
  color: #D70A84;
  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  letter-spacing: 4px;
  display: block;
}


.subTopic {
  width: Fill (1, 248px);
  height: Hug (45px);
  gap: 8px;
  color: #51127F;
  font-weight: 700;
  font-size: 32px;
  line-height: 44.8px;
  /* text-align: center; */
  display: block;
}

.description {
  width: auto
}

.widgetLarge {
  flex: 1;
  margin-right: 5px;
  margin-left: 5px;
}

.widgetLarge a {
  color: inherit !important;
  text-decoration: none !important;
}

.designBox {
  display: flex;
  flex-direction: row;
  background-color: #F8F2FD;
  border-radius: 16px;
  padding: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: auto;
  transition: transform 0.25s ease-in-out
}

@media screen and (max-width: 768px){
  .designBox {
    display: flex;
    flex-direction:column;
    background-color: #F8F2FD;
    border-radius: 16px;
    padding: 24px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: auto;
    transition: transform 0.25s ease-in-out
  }
}

.designBox:hover {
  transform: scale(1.01);
}

.designBoxSmall:hover {
  transform: scale(1.01);
}

@media screen and (max-width: 768px) {
  .widgetLarge {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .widgetLarge {
    margin-right: 0;
  }
}

.widgetSmallContainer {
  display: flex;
  flex-direction: column;
}

.silverPartners,
.coPartners,
.partners {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.designBoxSmall {
  display: inline-block;
  background-color: #F8F2FD;
  border-radius: 16px;
  padding: 24px 24px 0px 24px ;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  transition: transform 0.25s ease-in-out
}

@media screen and (max-width: 1440px) {
  .designBoxSmall {
    width: calc(100%/3 - 6px);
    transition: transform 0.25s ease-in-out
  }
}

@media screen and (max-width: 1024px) {
  .designBoxSmall {
    width: calc(100%/2 - 8px);
  }
}

@media screen and (max-width: 1024px) {
  .designBoxSmall {
    width: calc(100%);
  }
}


@media screen and (min-width: 1441px) {
  .designBoxSmall {
    width: calc(100%/3 - 6px);
  }
}


.companyName {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  margin-top: 10px;
}

.company-name-small {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
}


.partnership {
  color: #000000;
  font-size: 21px;
  font-weight: 400;
  line-height: 29.4px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
}

.companyDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 16px;
}

.companyLogoSmall {
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 16px
}

.companyLogoLarge {
  align-items: center;
  border-radius: 16px;
  width: 100%;
  height: auto;
}

.large-company-card-image-container {
  width: 400PX;
}

@media screen and (max-width: 768px) {
  .companyLogoLarge {
    align-items: center;
    border-radius: 16px;
    width: 100%;
    height: 180px;
    object-fit: cover; 
    object-position: center;
  }
}
@media screen and (min-width: 769px) {
  .companyLogoLarge {
    align-items: center;
    border-radius: 16px;
    width: 100%;
    height: 200px; 
    object-fit: cover; 
    object-position: center;
  }
}