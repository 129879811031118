.about-text h3 {
    font-size: 35px;
    font-weight: 800;
}

@media (max-width: 767px) {
    .about-text h3 {
        font-size: 35px;
    }
}

.about-text h6 {
    font-weight: 600;
    margin-bottom: 15px;
}

@media (max-width: 1000px) {
    .about-text h6 {
        font-size: 18px;
    }
}

.about-text p {
    font-size: 18px;
}

.about-list {
    padding-top: 10px;
}

.about-list .media {
    white-space: nowrap;
}

.about-list label {
    color: #20247b;
    font-weight: 600;
    width: 88px;
    position: relative;
}

.about-list p {
    font-size: 15px;
    color: #6F8BA4;
}

.dark-color {
    color: #20247b;
}

@media (max-width: 770px) {
    .about-text {
        text-align: center;
    }
}

.about-me h3 {
    font-size: 45px;
    font-weight: 700;
}

.custom-chip {
    color: #20247b;
    margin-bottom: 20px;
    margin-top: 20px;
}

.custom-icon {
    cursor: pointer;
    color: #0077b5;
}

.basic-text {
    font-size: 18px;
    color: #6F8BA4;
}

.paper {
    transition: 0.3s !important;
    margin-bottom: 8px !important; 
    border-radius: 100% !important;
    background-color: black;
  }
  
  .paper:hover {
    transform: scale(1.05);
  }

  .centered-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .flex-wrap-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    cursor: pointer;
  }

  .justified-text {
    font-size: 16px!important;
    text-align: justify;
  }

  @media (max-width: 770px) {
    .flex-wrap-container {
        gap: 10px;
    }
}

.flex-wrap-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; 
  gap: 10px; 
}

.justified-text {
  text-align: justify;
}

.technical-skills {
    padding-bottom: 10px;
}
